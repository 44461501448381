import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { useSelector } from 'react-redux';

import BreederProfileNearbyCities from 'dpl/components/BreederProfile/Index/BreederProfileList/BreederProfileNearbyCities';
import RelatedBreedsModule from 'dpl/puppy_search_results/components/v2/RelatedBreedsModule';

export const HEADER_HEIGHT = 74;

export default function BreederProfileLayout({ sections, topSections }) {
  const { breeds, breederProfileId, citySlug, locationTitle } = useSelector(
    ({ server }) => server
  );

  return (
    <div className="BreederProfileLayout">
      <div className="bb b--lightest-gray mb6 mb20-lg">
        {topSections.map(({ Component, key, props }) => (
          <div key={key}>
            <Component {...props} />
          </div>
        ))}
        <div className="container-xxl">
          {sections.map(({ Component, key, props }, idx) => (
            <div
              key={key}
              className={classnames('pv6 pv12-lg b--lightest-gray', {
                'bb b--lightest-gray': sections.length - 1 !== idx
              })}
            >
              <Component {...props} />
            </div>
          ))}
        </div>
      </div>
      <div className="BreederRelatedLinks container-xxl">
        <RelatedBreedsModule className="mb6 mb20-lg" />
        {Boolean(citySlug || locationTitle) &&
          breeds.map(b => (
            <BreederProfileNearbyCities
              key={b.id}
              breedId={b.id}
              breederProfileId={breederProfileId}
              breedName={b.name}
              limit={10}
              wideLayout
            />
          ))}
      </div>
    </div>
  );
}

BreederProfileLayout.propTypes = {
  topSections: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      count: PropTypes.number,
      Component: PropTypes.oneOfType([
        PropTypes.node,
        PropTypes.func,
        PropTypes.object
      ]).isRequired
    })
  ),
  sections: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      count: PropTypes.number,
      Component: PropTypes.oneOfType([
        PropTypes.node,
        PropTypes.func,
        PropTypes.object
      ]).isRequired
    })
  )
};

BreederProfileLayout.defaultProps = {
  topSections: [],
  sections: []
};
