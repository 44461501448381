import React from 'react';

import __DEPRECATED_defineResources from 'dpl/shared/fetching/deprecated/defineResources';
import BreederProfileAdoptionProcessSection from 'dpl/components/BreederProfile/Show/BreederProfileAdoptionProcessSection';
import isPreviewPage from 'dpl/shared/utils/isPreviewPage';

function BreederProfileAdoptionProcessSectionContainer(props) {
  return <BreederProfileAdoptionProcessSection {...props} />;
}

export default __DEPRECATED_defineResources(
  ({ server: { breederProfileId } }) => ({
    adoptionProcessData: {
      url: `/api/breeder_profiles/${breederProfileId}/adoption_process.json`,
      defaultValue: {
        data: {}
      },
      params: {
        preview: isPreviewPage()
      }
    }
  })
)(BreederProfileAdoptionProcessSectionContainer);
