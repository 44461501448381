import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import * as Cookie from 'js-cookie';

import { TIMES_USER_SEEN_MORE_ARROW_COOKIE } from 'dpl/shared/constants/cookies';
import { makeSequence } from 'dpl/shared/utils';
import ZoomingPhoto from 'dpl/components/ZoomingPhoto';
import HorizontalSlider from 'dpl/components/HorizontalSlider';
import {
  PHOTO_ALBUM_CATEGORIES_HEIGHT,
  PHOTO_ALBUM_VERTICAL_PADDING,
  PHOTO_ALBUM_THUMBS_WIDTH
} from 'dpl/constants/photo_album';

const FIXED_COVER_PHOTO_COUNT = 3;
const SHOW_MORE_ARROW_VISIT_QUOTA = 2;
const LAZY_IMAGE_BUFFER = window.outerWidth;

function BreederProfilePhotosAndVideosCollagePlaceholders() {
  return makeSequence(FIXED_COVER_PHOTO_COUNT + 1).map(idx => (
    <div
      key={idx}
      className="BreederProfilePhotosAndVideosCollage__image dib h-100"
    >
      <div className="bg-light-gray h-100" />
    </div>
  ));
}

export default class BreederProfilePhotosAndVideosCollage extends Component {
  static propTypes = {
    images: PropTypes.shape({
      value: PropTypes.shape({
        data: PropTypes.arrayOf(
          PropTypes.shape({
            id: PropTypes.number.isRequired
          })
        ).isRequired
      }).isRequired
    }).isRequired,
    onImageClick: PropTypes.func.isRequired,
    currentImageId: PropTypes.number
  };

  static defaultProps = {
    currentImageId: null
  };

  static getDerivedStateFromProps(nextProps) {
    const currentTimesUserSeenMoreArrow =
      Cookie.getJSON(TIMES_USER_SEEN_MORE_ARROW_COOKIE) || 0;

    const shouldShowRightArrow =
      nextProps.images.value.data.length > FIXED_COVER_PHOTO_COUNT &&
      currentTimesUserSeenMoreArrow < SHOW_MORE_ARROW_VISIT_QUOTA;

    if (shouldShowRightArrow) {
      Cookie.set(
        TIMES_USER_SEEN_MORE_ARROW_COOKIE,
        currentTimesUserSeenMoreArrow + 1,
        {
          expires: 100
        }
      );

      return { shouldAnimatePeek: true };
    }

    return null;
  }

  state = {
    shouldAnimatePeek: false
  };

  scrollContainerRef = null;

  handleAnimationEnd = () => this.setState({ shouldAnimatePeek: false });

  render() {
    const { images, onImageClick, currentImageId } = this.props;

    const { shouldAnimatePeek } = this.state;

    const { data: imagesData } = images.value;

    return (
      <div
        className={classnames(
          'BreederProfilePhotosAndVideosCollage overflow-hidden',
          {
            'BreederProfilePhotosAndVideosCollage--animated': shouldAnimatePeek
          }
        )}
      >
        <HorizontalSlider
          className="BreederProfilePhotosAndVideosCollage__container f0"
          iconClassName="b gd-icon--shadow"
          onAnimationEnd={this.handleAnimationEnd}
          persistentArrows={shouldAnimatePeek}
          setScrollContainerRef={el => {
            this.scrollContainerRef = el;
          }}
        >
          <div className="BreederProfilePhotosAndVideosCollage__fixedImages dib">
            {imagesData.length === 0 ? (
              <BreederProfilePhotosAndVideosCollagePlaceholders />
            ) : (
              imagesData.slice(0, FIXED_COVER_PHOTO_COUNT).map(image => (
                <div
                  key={image.id}
                  className="BreederProfilePhotosAndVideosCollage__image dib v-top h-100"
                >
                  <ZoomingPhoto
                    image={image}
                    smartImageProps={{
                      lazyBuffer: LAZY_IMAGE_BUFFER,
                      lazyContainerRef: this.scrollContainerRef
                    }}
                    onClick={onImageClick}
                    isDisplayed={image.id === currentImageId}
                    albumPadding={{
                      top:
                        PHOTO_ALBUM_CATEGORIES_HEIGHT +
                        PHOTO_ALBUM_VERTICAL_PADDING / 2,
                      bottom: PHOTO_ALBUM_VERTICAL_PADDING / 2,
                      right: PHOTO_ALBUM_THUMBS_WIDTH
                    }}
                  />
                </div>
              ))
            )}
          </div>
          <div className="BreederProfilePhotosAndVideosCollage__fluidImages top-0 bottom-0 dib absolute h-100">
            {imagesData.slice(FIXED_COVER_PHOTO_COUNT).map(image => (
              <div
                key={image.id}
                className="BreederProfilePhotosAndVideosCollage__image h-100 dib v-top"
              >
                <ZoomingPhoto
                  retainAspectRatio
                  image={image}
                  smartImageProps={{
                    lazyBuffer: LAZY_IMAGE_BUFFER,
                    lazyContainerRef: this.scrollContainerRef
                  }}
                  onClick={onImageClick}
                  isDisplayed={image.id === currentImageId}
                  albumPadding={{
                    top:
                      PHOTO_ALBUM_CATEGORIES_HEIGHT +
                      PHOTO_ALBUM_VERTICAL_PADDING / 2,
                    bottom: PHOTO_ALBUM_VERTICAL_PADDING / 2,
                    right: PHOTO_ALBUM_THUMBS_WIDTH
                  }}
                />
              </div>
            ))}
          </div>
        </HorizontalSlider>
      </div>
    );
  }
}
