import AdoptionApplicationOverlayContainer from 'dpl/adoption_application/containers/OverlayContainer';
import BreederProfileApplyButtonContainer from 'dpl/containers/BreederProfile/Show/BreederProfileApplyButtonContainer';
import BreederProfileFavoriteButton from 'dpl/components/BreederProfile/Show/BreederProfileFavoriteButton';
import BreederProfileFixedHeaderActions from 'dpl/components/BreederProfile/Show/BreederProfileFixedHeaderActions';
import BreederProfileLayoutContainer from 'dpl/containers/BreederProfile/Show/BreederProfileLayoutContainer';
import BreederProfilePhotoAlbumContainer from 'dpl/containers/BreederProfile/Show/BreederProfilePhotoAlbumContainer';
import BreederProfilePhotosAndVideosContainer from 'dpl/containers/BreederProfile/Show/BreederProfilePhotosAndVideosContainer';
import BreederProfileQuestionButton from 'dpl/components/BreederProfile/Show/BreederProfileQuestionButton';
import BreederProfileTestimonialModal from 'dpl/containers/BreederProfile/Index/BreederProfileTestimonialModal';
import InitialInquiryQuiz from 'dpl/initial_inquiry/components/InitialInquiryQuiz';
import BuyerProfileQuiz from 'dpl/initial_inquiry/components/BuyerProfileQuiz';
import LitterModal from 'dpl/dog_modals/litter_modal/components/LitterModal';
import NewsletterFormContainer from 'dpl/containers/NewsletterFormContainer';
import ParentModal from 'dpl/dog_modals/parent_modal/components/ParentModal';
import PuppyModal from 'dpl/dog_modals/puppy_modal/components/PuppyModal';
import PreferredBreederBadge from 'dpl/breeder_preferred_qualifications/components/PreferredBreederBadge';
import breederFragmentRedirect from 'dpl/util/breederFragmentRedirect';
import initReactApp from 'dpl/util/initReactApp';
import { ANALYTICS_EVENTS, ANALYTICS_VIEWS } from 'dpl/constants/analytics';
import { sendRudderstackEvent } from 'dpl/util/analytics';
import { setAnalyticsOrigin } from 'dpl/shared/utils/setAnalyticsOrigin';

setAnalyticsOrigin({
  originView: ANALYTICS_VIEWS.BREEDER_PROFILE_PAGE
});

if (!breederFragmentRedirect()) {
  sendRudderstackEvent(
    ANALYTICS_EVENTS.BREEDER_PROFILE_VIEWED,
    window.__DPL_EVENT_PROPS
  );

  initReactApp({
    BreederProfilePhotosAndVideosContainer,
    BreederProfileLayoutContainer,
    BreederProfileQuestionButton,
    BreederProfileApplyButtonContainer,
    BreederProfileFixedHeaderActions,
    BreederProfilePhotoAlbumContainer,
    AdoptionApplicationOverlayContainer,
    NewsletterFormContainer,
    BreederProfileTestimonialModal,
    BreederProfileFavoriteButton,
    InitialInquiryQuiz,
    BuyerProfileQuiz,
    PreferredBreederBadge,
    PuppyModal,
    ParentModal,
    LitterModal
  });
}
