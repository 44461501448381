import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';

import __DEPRECATED_defineResources from 'dpl/shared/fetching/deprecated/defineResources';
import BreederProfilePhotosAndVideosCollage from 'dpl/components/BreederProfile/Show/BreederProfilePhotosAndVideosCollage';
import { photographsResourceDefinition } from 'dpl/shared/utils/tptConnectResourceDefinitions';
import { IMAGE_ID } from 'dpl/constants/query_params';
import { createBreederProfilePaginationAction } from 'dpl/containers/BreederProfile/Show/BreederProfilePhotoAlbumContainer';

function BreederProfilePhotosAndVideosContainer(props) {
  return <BreederProfilePhotosAndVideosCollage {...props} />;
}

export default compose(
  connect(
    ({ queryParams }) => ({
      currentImageId: queryParams[IMAGE_ID]
        ? Number(queryParams[IMAGE_ID])
        : null
    }),
    {
      onImageClick: createBreederProfilePaginationAction
    }
  ),
  __DEPRECATED_defineResources(({ server, queryParams }) => ({
    images: photographsResourceDefinition(server.breederProfileId, {
      breed_id: queryParams.breed_id
    })
  }))
)(BreederProfilePhotosAndVideosContainer);
