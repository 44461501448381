import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';

import BreederProfileLayout from 'dpl/components/BreederProfile/Show/BreederProfileLayout';
import BreederProfileIntroductionSectionContainer from 'dpl/containers/BreederProfile/Show/BreederProfileIntroductionSectionContainer';
import BreederProfileTestimonialsSection, {
  testimonialsSectionVisibilitySelector
} from 'dpl/components/BreederProfile/Show/BreederProfileTestimonialsSection';
import BreederProfileAboutBreedsSectionContainer, {
  preferencesResourceMapper
} from 'dpl/containers/BreederProfile/Show/BreederProfileAboutBreedsSectionContainer';
import BreederProfileCurrentPuppiesSectionContainer, {
  currentPuppiesResourceMapper
} from 'dpl/containers/BreederProfile/Show/BreederProfileCurrentPuppiesSectionContainer';
import BreederProfileLittersSectionContainer, {
  littersResourceMapper
} from 'dpl/containers/BreederProfile/Show/BreederProfileLittersSectionContainer';
import BreederProfileAdoptionProcessSectionContainer from 'dpl/containers/BreederProfile/Show/BreederProfileAdoptionProcessSectionContainer';
import BreederProfileDeliverySectionContainer from 'dpl/containers/BreederProfile/Show/BreederProfileDeliverySectionContainer';
import BreederProfileAboutPuppiesSectionContainer, {
  aboutPuppiesResourceMapper
} from 'dpl/containers/BreederProfile/Show/BreederProfileAboutPuppiesSectionContainer';
import BreederProfileInterviewSectionContainer, {
  interviewResourceMapper
} from 'dpl/containers/BreederProfile/Show/BreederProfileInterviewSectionContainer';
import BreederProfileHealthTestsSectionContainer, {
  healthTestsResourceMapper
} from 'dpl/containers/BreederProfile/Show/BreederProfileHealthTestsSectionContainer';
import BreederProfileParentsSectionContainer, {
  parentsResourceMapper
} from 'dpl/containers/BreederProfile/Show/BreederProfileParentsSectionContainer';
import BreederProfilePhotosSectionContainer, {
  photosResourceMapper
} from 'dpl/containers/BreederProfile/Show/BreederProfilePhotosSectionContainer';

function BreederProfileLayoutContainer(props) {
  return <BreederProfileLayout {...props} />;
}

export default compose(
  preferencesResourceMapper,
  currentPuppiesResourceMapper,
  littersResourceMapper,
  aboutPuppiesResourceMapper,
  healthTestsResourceMapper,
  parentsResourceMapper,
  photosResourceMapper,
  interviewResourceMapper,
  connect((state, props) => ({
    topSections: [
      {
        Component: BreederProfileIntroductionSectionContainer,
        key: 'overview'
      },

      props.validPuppies.length && {
        Component: BreederProfileCurrentPuppiesSectionContainer,
        count: props.validPuppies.filter(p => !p.reserved).length,
        key: 'current-puppies'
      },

      props.litters.length && {
        Component: BreederProfileLittersSectionContainer,
        key: 'litters',
        props: {
          hasPuppies: Boolean(props.validPuppies.length)
        }
      }
    ].filter(Boolean),
    sections: [
      {
        Component: BreederProfileAdoptionProcessSectionContainer,
        key: 'adoption-process'
      },

      {
        Component: BreederProfileDeliverySectionContainer,
        key: 'delivery'
      },
      ['hasTraining', 'hasHealth', 'hasExtras', 'hasGuarantee'].some(
        k => props[k]
      ) && {
        Component: BreederProfileAboutPuppiesSectionContainer,
        key: 'about-puppies'
      },

      props.breedsWithPreferences.length && {
        Component: BreederProfileAboutBreedsSectionContainer,
        key: 'about-breeds'
      },

      // At least one interview question is answered and has a photo
      props.interview.value.data.answers.length &&
        props.interview.value.data.photo.url && {
          Component: BreederProfileInterviewSectionContainer,
          key: 'interview'
        },

      props.dogs.length && {
        Component: BreederProfileParentsSectionContainer,
        key: 'parents'
      },

      props.breedsWithHealthInfo.length && {
        Component: BreederProfileHealthTestsSectionContainer,
        key: 'health-tests'
      },

      props.imagesData.value.meta.public_gallery_photo_count && {
        Component: BreederProfilePhotosSectionContainer,
        key: 'photos'
      },
      testimonialsSectionVisibilitySelector(state) && {
        Component: BreederProfileTestimonialsSection,
        key: 'owner-stories',
        count: state.server.testimonialsCount
      }
    ].filter(Boolean)
  }))
)(BreederProfileLayoutContainer);
