import React from 'react';
import PropTypes from 'prop-types';

import { asTptConnectPropTypes } from 'dpl/shared/utils/customPropTypes';
import {
  HELP_CENTER_ON_PLATFORM_PAYMENT_POLICY_PATH,
  LEARNING_CENTER_PRICE_PATH
} from 'dpl/shared/constants/urls';
import ClampedMultiLineText from 'dpl/common/components/ClampedMultiLineText';
import Drawer, {
  DrawerItem,
  DrawerItemTitle,
  DrawerItemContent
} from 'dpl/common/components/Drawer';

export default function BreederProfileAdoptionProcessSection({
  adoptionProcessData
}) {
  const {
    value: { data: adoptionProcess }
  } = adoptionProcessData;

  return (
    <section className="BreederProfileAdoptionProcessSection">
      <h5 className="mb6 f5">How to get a puppy</h5>

      <div className="nh5">
        <div className="row no-gutters">
          <div className="col-md-6 mb8 mb0-md f3">
            <div className="ph5">
              <ClampedMultiLineText
                text={adoptionProcess.adoption_process_paragraph || ''}
                lineHeight={24}
                numOfLines={7}
              />
            </div>
          </div>
          <div className="col-md-6 nv4">
            <div className="ph5">
              <Drawer>
                <DrawerItem className="" itemKey="price">
                  <DrawerItemTitle arrowClassName="ml2 black">
                    <div className="flex items-center f3">
                      <span className="flex-auto fw-medium">Price</span>
                      <span>{adoptionProcess.formatted_price_range}</span>
                    </div>
                  </DrawerItemTitle>
                  <DrawerItemContent>
                    <p className="mb3 lh-copy">
                      {adoptionProcess.deposit_and_price_description}
                    </p>
                    <p>
                      Learn more about our{' '}
                      <a
                        className="underline"
                        href={HELP_CENTER_ON_PLATFORM_PAYMENT_POLICY_PATH}
                      >
                        On-Platform Payment Policy
                      </a>{' '}
                      or{' '}
                      <a
                        className="underline"
                        href={LEARNING_CENTER_PRICE_PATH}
                      >
                        learn more about puppy pricing
                      </a>
                      .
                    </p>
                  </DrawerItemContent>
                </DrawerItem>

                {adoptionProcess.go_home_after_weeks && (
                  <DrawerItem className="" itemKey="go_home_date">
                    <DrawerItemTitle arrowClassName="ml2 black">
                      <div className="flex items-center f3">
                        <span className="flex-auto fw-medium">
                          Go Home Date
                        </span>
                        <span>
                          {adoptionProcess.go_home_after_weeks} Weeks After
                          Birth
                        </span>
                      </div>
                    </DrawerItemTitle>
                    <DrawerItemContent>
                      <p>
                        Puppies need time to nurse, grow and develop with their
                        mother and littermates before they can join their new
                        families. A newborn puppy should stay with their
                        breeder, mom, and siblings as they develop physically
                        and gain new social skills alongside their brothers and
                        sisters. The age a puppy can go home depends on the
                        breed, and even the individual, but generally is around
                        two months.
                      </p>
                    </DrawerItemContent>
                  </DrawerItem>
                )}
              </Drawer>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

BreederProfileAdoptionProcessSection.propTypes = {
  adoptionProcessData: asTptConnectPropTypes(
    PropTypes.shape({
      id: PropTypes.number,
      adoption_process_paragraph: PropTypes.string,
      formatted_price_range: PropTypes.string,
      waitlist: PropTypes.bool,
      waitlist_description: PropTypes.string,
      deposit: PropTypes.string,
      deposit_and_price_description: PropTypes.string,
      go_home_after_weeks: PropTypes.number
    }).isRequired
  ).isRequired
};
