import React, { Fragment } from 'react';

import BreederProfileQuestionButton from 'dpl/components/BreederProfile/Show/BreederProfileQuestionButton';
import BreederProfileApplyButtonContainer from 'dpl/containers/BreederProfile/Show/BreederProfileApplyButtonContainer';
import {
  ANALYTICS_VIEWS,
  ANALYTICS_VIEW_FEATURE_LOCATIONS
} from 'dpl/constants/analytics';

export default function BreederProfileFixedHeaderActions() {
  return (
    <Fragment>
      <BreederProfileQuestionButton
        className="h-100 ph12 inline-flex items-center link--primary fw-medium"
        withIcon
      />
      <BreederProfileApplyButtonContainer
        className="h-100 ph12 inline-flex items-center button button--primary br0 fw-medium"
        withIcon
        analytics={{
          view: ANALYTICS_VIEWS.BREEDER_PROFILE_PAGE,
          view_feature_location: ANALYTICS_VIEW_FEATURE_LOCATIONS.HEADER
        }}
      />
    </Fragment>
  );
}
